import React from 'react'

function Social() {
    return (
        <div className="social-networks">
            <a href="https://www.instagram.com/parmacaffe/">
                <div className="icon instagram"></div>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100037404206510">
                <div className="icon facebook"></div>
            </a>
            <a href="tel:0631606456">
                <div className="icon phone"></div>
            </a>
        </div>
    )
}

export default Social