import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../utils/api';
import { useParams } from 'react-router-dom';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../components/StrictModeDroppable';


function Items() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editData, setEditData] = useState(null);

    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState("");

    const { id } = useParams();

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handlePriceChange(event) {
        setPrice(event.target.value);
    }

    function handleDescriptionChange(event) {
        setDescription(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        let submitPromise = null;

        if (editData) {
            submitPromise = axios.put(`${API_URL}/api/item/${editData.id}`, {
                name,
                price,
                description
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
        } else {
            submitPromise = axios.post(`${API_URL}/api/item/`, {
                name,
                price,
                description,
                subcategory_id: id
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
        }

        submitPromise.then((res) => {
            if (editData) {
                const newState = items.map(obj => {
                    if (obj.id === res.data.id) {
                        return { ...obj, name: res.data.name, price: res.data.price, description: res.data.description };
                    }

                    return obj;
                });

                setItems(newState);
            } else {
                setItems((prevState) => {
                    return [...prevState, res.data];
                });
            }

            setEditData(null);
            setName('');
            setPrice(0);
            setDescription('');
        })
    }

    const handleItemDelete = (id) => {
        axios.delete(`${API_URL}/api/item/${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setItems((prevState) => {
                return prevState.filter((it) => it.id !== res.data.id)
            });
            // HENDLUJ DA IZBRISE IZ STATE KATEGORIJU
        })
    }

    const handleItemUpdate = (item) => {
        setEditData(item);
        setName(item.name);
        setPrice(item.price);
        setDescription(item.description ?? '');
    }

    const handleFormReset = () => {
        setEditData(null);
        setName('');
        setPrice(0);
        setDescription('');
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/item/${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setItems(res.data);
        }).catch((e) => {
            setError(e.message);
            setItems([]);
        })
        setIsLoading(false);
    }, [])

    function handleDragEnd(result) {
        // Get the updated item list after drag and drop
        const updatedItems = Array.from(items);
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);

        // Get the list of item IDs and their indexes
        const updatedItemIds = updatedItems.map((item) => item.id);
        const updatedItemIndexes = updatedItems.map((item, index) => index);

        // Do something with the updated items and their indexes
        setItems(updatedItems);
        axios.post(`${API_URL}/api/item/update-index`, {
            updatedItemIds,
            updatedItemIndexes
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            console.log(updatedItemIds);
            console.log(updatedItemIndexes);
        });
    }

    return (
        <div className='admin-categories'>
            <div className='admin-add-category'>
                <div className='admin-form-title'>{editData ? 'Izmeni izabrani proizvod' : 'Dodaj proizvod'}</div>
                <form className='admin-form' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Naziv:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                        /><br />
                        <label htmlFor="price">Cena:</label>
                        <input
                            type="number"
                            id="price"
                            value={price}
                            onChange={handlePriceChange}
                        /><br />
                        <label htmlFor="desc">Opis:</label>
                        <input
                            type="text"
                            id="desc"
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <button className='admin-submit' type="submit">{editData ? 'Izmeni' : 'Dodaj'}</button>
                    {editData && <button className='admin-reset' onClick={handleFormReset}>Otkazi</button>}
                </form>
            </div>
            <div className='admin-title'> Lista svih proizvoda za zadatu podkategoriju </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <div className='subcategory-selector'>
                    <StrictModeDroppable droppableId="my-droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {items.map((item, index) => {
                                    return (
                                        <Draggable key={item.id} draggableId={`id${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className='admin-subcategory'>
                                                        {item.name}<br />
                                                        {item.price}<br />
                                                        {item.description}
                                                        <div className='admin-buttons'>
                                                            <button className='admin-button admin-red' onClick={() => handleItemDelete(item.id)}>Obrisi</button>
                                                            <button className='admin-button admin-green' onClick={() => handleItemUpdate(item)}>Izmeni</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </StrictModeDroppable>

                </div>
            </DragDropContext>
        </div>
    )
}

export default Items