import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../utils/api';
import { removeAccents } from '../utils/latin';

function Category() {
    const [menu, setMenu] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    let { id } = useParams();

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/subcategory/${id}`).then((res) => {
            console.log(res);
            setMenu(res.data);
        }).catch((e) => {
            setError(e.message);
            setMenu([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <div className="container">
            <Header />
            <div className="menu-padding">
                {
                    menu.map((men) => {
                        return (
                            <div key={men.id}>
                                <div className="welcome-text">{removeAccents(men.name)}</div>
                                <div className="menu-items">
                                    {
                                        men.items.map((item) => {
                                            return (
                                                <div key={item.id} className="menu-item">
                                                    <div className="menu-item-top">
                                                        <div className="name">{item.name}</div>
                                                        <div className="price">{item.price} din</div>
                                                    </div>
                                                    {item.description && <div className="menu-desc">({item.description})</div>}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Footer />
        </div>
    )
}

export default Category