export const resizeAndConvertToWebp = async (file, maxWidth, maxHeight) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Load the image into a new Image object
    const image = new Image();
    image.src = URL.createObjectURL(file);

    // Wait for the image to load
    await new Promise(resolve => {
        image.onload = () => {
            resolve();
        };
    });

    // Resize the image
    let width = image.width;
    let height = image.height;
    if (width > height) {
        if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
        }
    }
    canvas.width = width;
    canvas.height = height;
    context.drawImage(image, 0, 0, width, height);

    // Convert the image to WebP format
    const webpData = canvas.toDataURL("image/webp");
    const webpFile = await fetch(webpData)
        .then(res => res.blob())
        .then(blob => new File([blob], "image.webp", { type: "image/webp" }));

    return webpFile;
};
