export function removeAccents(string) {
    string = string.replace(/[šđčć]/gi, function (match) {
        switch (match.toLowerCase()) {
            case 'š': return 's';
            case 'đ': return 'd';
            case 'č': return 'c';
            case 'ć': return 'c';
            case 'ž': return 'z';
            default: return match;
        }
    });
    return string;
}